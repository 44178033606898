<!-- @Author: xiaodongyu -->
<!-- @Date: 2019/8/19-19:52 -->
<!-- @Last Modified by: lijiahang -->
<!-- @Last Modified time: 2023-12-06 13:34:02 -->

<template>
    <div
        class="static-agreement"
        :class="{
            'sign-padding': signBtnVisible || confirmBtnVisible || isAndroidBug,
            'raw-bg': isRaw,
            [`${brand}-agreement`]: brand
        }"
        :style="agreementStyle"
    >
        <yqg-md-preview :md-text="content" />

        <android-bug-btn v-if="isAndroidBug" />

        <agreement-confirm-btn
            v-if="confirmBtnVisible"
            :is-bottom="isBottom"
            :at-bottom="atBottom"
        />
        <agreement-download-btn />
        <agreement-sign-btn
            :sign-btn-visible="signBtnVisible"
            :title-list="titleList"
            :is-debt="isDebt"
            :is-auto-debt="isAutoDebt"
            :auto="false"
            :now-index="nowIndex"
            @btnMount="onBtnMount"
            @agreementChange="onAgreementChange"
        />
    </div>
</template>

<script>
import {mapState} from 'vuex';

import AndroidBugBtn from 'easycash/common/component/android-bug-btn';
import AgreementConfirmBtn from 'easycash/common/component/ec-agreement-confirm-btn';
import AgreementDownloadBtn from 'easycash/common/component/ec-agreement-download-btn';
import AgreementSignBtn from 'easycash/common/component/ec-agreement-sign-btn';
import asyncContentManage from 'easycash/common/mixin/async-content-manage';
import Agreement from 'easycash/common/resource/agreement';

export default {
    name: 'StaticAgreement',

    components: {
        AgreementConfirmBtn,
        AgreementDownloadBtn,
        AgreementSignBtn,
        AndroidBugBtn
    },

    mixins: [asyncContentManage({query: Agreement.getContentByKey})],

    data() {
        return {
            btnHeight: 0,
            defaultTitle: 'Agreement'
        };
    },

    computed: {
        ...mapState('baseData', [
            'brand'
        ]),

        isDebt() {
            const {productType} = this.$route.query;

            return productType === 'debt';
        },

        isAutoDebt() {
            const {productType} = this.$route.query;

            return productType === 'autoDebt';
        },

        agreementStyle() {
            const {isDebt, btnHeight} = this;

            return isDebt ? {
                paddingBottom: `${btnHeight + 20 }px`
            } : {};
        },

        isAndroidBug() {
            const {$route: {params, query}} = this;
            const isKey = params.key === 'INVESTAJA_RDL_ACCOUNT';
            const isPurpose = query.purpose === 'LOAN_REGISTER_AGREEMENT';

            return isKey && isPurpose;
        }
    },

    methods: {
        onAgreementChange(index) {
            this.nowIndex = index;
            window.scrollTo(0, 0);
        },

        onBtnMount(height) {
            this.btnHeight = height;
        }
    }
};
</script>
<style lang="scss" scoped>
@font-face {
    font-family: "Zapfino";
    font-weight: 400;
    src:
        url("~easycash/common/style/font-face/Zapfino.woff2"),
        url("~easycash/common/style/font-face/Zapfino.ttf");
    font-display: swap;
}

div.static-agreement {
    box-sizing: border-box;
    overflow-x: hidden;

    &.credmex-agreement {
        color: #7e7e8a;
        font-family: Lexend;
        font-style: normal;
        font-weight: 400;
        line-height: 165%;

        ::v-deep {
            h2,
            h4,
            h6 {
                color: #7e7e8a;
                font-family: Lexend;
                font-style: normal;
                font-weight: 400;
                line-height: 165%;
            }
        }
    }

    ::v-deep {
        .agreement-sign-btn {
            box-sizing: border-box;
            width: 100%;
            padding: 0.2rem;
        }
    }

    p {
        margin-bottom: 16px;
    }

    &.raw-bg {
        background-color: #fff;
    }

    &.sign-padding {
        padding-bottom: 1.25rem;
    }
}
</style>
