<!--
 * @Author: zhaoyang
 * @Date: 2023-09-13 13:13:45
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-09-13 18:57:56
-->

<template>
    <div
        class="android-bug-btn"
    >
        <div
            class="scroll-btn"
            @click="onBottom"
        >
            <span>
                {{ $t(`webview.protocol.scrollBtn`) }}
                <van-icon
                    name="arrow-down"
                    style="top: 0.02rem;"
                />
            </span>
        </div>
        <button
            :style="{
                background: disabled ? '#ababab' : '#03DA8B'
            }"
            :disabled="disabled"
            @click="onConfirm"
        >
            {{ $t('webview.ovo.confirm') }}
        </button>
    </div>
</template>

<script>
import {debounce} from 'lodash';
import {Icon} from 'vant';

import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';

export default {
    name: 'AndroidBugBtn',

    components: {
        [Icon.name]: Icon
    },

    data() {
        return {
            isBottom: false
        };
    },

    computed: {
        disabled() {
            return !this.isBottom;
        }
    },

    mounted() {
        if (history.scrollRestoration) {
            history.scrollRestoration = 'manual';
        }

        window.addEventListener('scroll', this.onScroll);
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
    },

    methods: {
        onScroll: debounce(function () {
            const {scrollHeight} = document.documentElement;
            const clientHeight = window.innerHeight;
            this.isBottom = window.scrollY + clientHeight >= scrollHeight - 20;
        }, 50),

        onBottom() {
            window.scrollTo(0, 1000000);
        },

        onConfirm() {
            const {$route: {query: {purpose}}} = this;
            WebviewInterface.confirmAgreement(purpose);
            WebviewInterface.closeWebPage();
        }
    }
};
</script>

<style lang="scss" scoped>
.android-bug-btn {
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0.2rem;
    background: #f5fbfb;

    .scroll-btn {
        width: max-content;
        padding: 0 0.1rem;
        position: absolute;
        height: 0.3rem;
        left: 50%;
        top: -0.4rem;
        display: flex;
        align-items: center;
        border-radius: 0.15rem;
        color: #fff;
        font-size: 0.12rem;
        background-color: rgba(0, 0, 0, 0.6);
        transform: translateX(-50%);
    }

    button {
        width: 100%;
        height: 0.6rem;
        border-radius: 0.08rem;
        border: none;
        outline: none;
        font-size: 0.18rem;
        color: #fff;
    }
}
</style>
