var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "android-bug-btn" }, [
    _c("div", { staticClass: "scroll-btn", on: { click: _vm.onBottom } }, [
      _c(
        "span",
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("webview.protocol.scrollBtn")) +
              "\n            "
          ),
          _c("van-icon", {
            staticStyle: { top: "0.02rem" },
            attrs: { name: "arrow-down" },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        style: {
          background: _vm.disabled ? "#ababab" : "#03DA8B",
        },
        attrs: { disabled: _vm.disabled },
        on: { click: _vm.onConfirm },
      },
      [_vm._v("\n        " + _vm._s(_vm.$t("webview.ovo.confirm")) + "\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }