var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "static-agreement",
      class:
        ((_obj = {
          "sign-padding":
            _vm.signBtnVisible || _vm.confirmBtnVisible || _vm.isAndroidBug,
          "raw-bg": _vm.isRaw,
        }),
        (_obj[_vm.brand + "-agreement"] = _vm.brand),
        _obj),
      style: _vm.agreementStyle,
    },
    [
      _c("yqg-md-preview", { attrs: { "md-text": _vm.content } }),
      _vm._v(" "),
      _vm.isAndroidBug ? _c("android-bug-btn") : _vm._e(),
      _vm._v(" "),
      _vm.confirmBtnVisible
        ? _c("agreement-confirm-btn", {
            attrs: { "is-bottom": _vm.isBottom, "at-bottom": _vm.atBottom },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("agreement-download-btn"),
      _vm._v(" "),
      _c("agreement-sign-btn", {
        attrs: {
          "sign-btn-visible": _vm.signBtnVisible,
          "title-list": _vm.titleList,
          "is-debt": _vm.isDebt,
          "is-auto-debt": _vm.isAutoDebt,
          auto: false,
          "now-index": _vm.nowIndex,
        },
        on: {
          btnMount: _vm.onBtnMount,
          agreementChange: _vm.onAgreementChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }